const transitionNames = ['none', 'push', 'pop', 'slide-left', 'slide-right']

export default {
  state: {
    pageTransitionName: 'push',
    pageStack:[]
  },
  mutations: {
    setField (state, info) {
      for (let key in info) {
        state[key] = info[key]
      }
    },
    setTransitionName (state, name) {
      state.pageTransitionName = name
    }
  },

  actions: {
    setPageTransitionName ({ commit }, name) {
      if (transitionNames.indexOf(name) === -1) {
        console.error('/store/modules/global.js: 未配置该过渡名称' + name + JSON.stringify(transitionNames))
        return 
      }
      commit('setTransitionName', name)
    },

    setPageStack ({ commit }, pageStack) {
      commit('setField', { pageStack })
    }
  }
  
}