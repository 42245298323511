import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import MieTao from '@/views/mieTao/index.vue'
import Login from '@/views/mieTao/login.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: '首页',
        },
    },
    {
        path: '/bloglist',
        name: 'BlogList',
        component: (resolve) => require(['@/views/Blog/blogList.vue'], resolve),
        meta: {
            title: '博客',
        },
    },
    {
        path: '/about',
        name: 'About',
        component: (resolve) => require(['@/views/About.vue'], resolve),
        meta: {
            title: '关于',
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            title: '登录',
        },
    },
    {
        path: '/mietao',
        name: 'MieTao',
        component: MieTao,
        redirect: 'index',
        children: [
            {
                path: '/index',
                name: 'index',
                component: (resolve) => require(['@/views/mieTao/convertTKL.vue'], resolve),
                meta: {
                    title: '转换工具',
                    type: 'login',
                },
            },
            {
                path: '/searchlist',
                name: 'SearchList',
                component: (resolve) => require(['@/views/mieTao/searchList.vue'], resolve),
                meta: {
                    title: '搜索',
                    type: 'login',
                },
            },
            {
                path: '/usercenter',
                name: 'UserCenter',
                component: (resolve) => require(['@/views/mieTao/userCenter.vue'], resolve),
                meta: {
                    title: '我的',
                    type: 'login',
                },
            },
            {
                path: '/history',
                name: 'history',
                component: (resolve) => require(['@/views/mieTao/history.vue'], resolve),
                meta: {
                    title: '转换记录',
                    type: 'login',
                },
            },
            {
                path: '/orderlist',
                name: 'orderlist',
                component: (resolve) => require(['@/views/mieTao/orderlist.vue'], resolve),
                meta: {
                    title: '订单列表',
                    type: 'login',
                },
            },
            {
                path: '/userSetting',
                name: 'userSetting',
                component: (resolve) => require(['@/views/mieTao/userSetting.vue'], resolve),
                meta: {
                    title: '个人信息',
                    type: 'login',
                },
            },
            {
                path: '/changeNickname',
                name: 'userSetting',
                component: (resolve) => require(['@/views/mieTao/changeNickname.vue'], resolve),
                meta: {
                    title: '修改昵称',
                    type: 'login',
                },
            },
            {
                path: '/changeOrderremark',
                name: 'userSetting',
                component: (resolve) => require(['@/views/mieTao/changeOrderremark.vue'], resolve),
                meta: {
                    title: '修改订单尾号',
                    type: 'login',
                },
            },
            {
                path: '/getOrderData',
                name: 'getOrderData',
                component: (resolve) => require(['@/views/mieTao/getOrderData.vue'], resolve),
                meta: {
                    title: '拉取订单数据',
                    type: 'login',
                },
            },
        ],
    },
    { path: '/404', name: 'page404', component: (resolve) => require(['@/views/page404'], resolve) },
    { path: '*', redirect: '/404', name: 'notFound', hidden: true },
]

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode:'history',
    routes,
})

export default router
