<template>
    <aside class="sidebar on">
        <div class="logo"></div>
        <ul>
            <li class="nav-item" v-for="(item, i) in navList" :key="i" :class="item.path === $route.path && 'active'" @click="change(item.path, i)">
                <span>{{ item.name }}</span>
            </li>
            <li v-if="userInfo && userInfo.level>0" class="nav-item" @click="$routerPush('/index')">
                <span>咩淘</span>
            </li>
            <li class="nav-item" @click="$routerPush('/login')" v-if="!haslogin">
                <span>登录</span>
            </li>
        </ul>
    </aside>
</template>

<script>
export default {
    data() {
        return {
            navList: [
                { 'path': '/', 'name': '主页' },
                { 'path': '/bloglist', 'name': '博客' },
                { 'path': '/about', 'name': '关于' },
                // { 'path': '/mietao', 'name': '咩淘' }
            ]
        }
    },
    computed:{
        haslogin(){
            return this.$store.state.user.hasLogin
        },
        userInfo(){
            return this.$store.state.user.userInfo
        }
    },
    methods:{
        change(path, i){
            let currentIndex = -1
            this.navList.find((nav, index) => {
                if (nav.path === this.$route.path) {
                    currentIndex = index
                    return true
                }
            })
            if (i === currentIndex) return
            this.$router.replace(path)
        }
    }
}
</script>

<style lang="scss" scoped>
.sidebar{
    position: fixed;
    z-index: 9;
    left: -100px;
    bottom: 0;
    width: 100px;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    .logo{
        width: 100px;
        height: 100px;
    }
    .nav-item{
        display: block;
        padding: 20px;
        color: #bbb;
        text-decoration: none;
        cursor: pointer;
    }
    &.on{
        left: 0;
    }
}
</style>