import { Asker } from '@coloration/asker'
import { Toast } from 'vant'
import store from '../store'

const scApi = new Asker({ 
    baseUrl: "",
    postType: 'form-urlencoded',
	before: (conf) => {
		if (store.state.user.userInfo.token) {
			conf.headers['authorization'] = store.state.user.userInfo.token;
		}
		return conf
	},
    after:(res) =>{
        if(res.status==200){
			if(res.data.code==0){
				return res.data
			}
			else{
				return Promise.reject(res.data)
			}
        }
        else{
            Toast(`网络异常 ${res.status}`)
			return Promise.reject(res.data)
        }
    },
	catcher: (e)=>{
		return Promise.reject(e.response?JSON.parse(e.response):e)
	},
    timeout: 30000,
    onTimeout: function(errType, xhr, conf){
        Toast("请求超时");
        console.log(errType, xhr, conf)
    }
})

const ask = {
	getExParams: {
		time:new Date().getTime().toString(),
		token:"",
	},
	post (apiPath, urlParams = {} ,baseUrl=window.urlData.api) {
		let askUrl = baseUrl + apiPath
		return scApi.post(askUrl, urlParams)
	},
	get (apiPath, urlParams = {} ,baseUrl=window.urlData.api) {
		let askUrl = baseUrl + apiPath
		return scApi.get(askUrl, urlParams)
	}
}


export { ask }