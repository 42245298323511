export default {
    state: {
        hasLogin: false, // 判断是否登录状态
        userInfo: {}, // 用户信息
    },
    mutations: {
        setInfo (state, info) {
            for (let key in info) state[key] = info[key]
        },
        login(state, provider) {
            state.hasLogin = true
            state.userInfo = provider
        },
        logout(state) {
            state.hasLogin = false
            state.userInfo = {}
        },
    },
    actions:{
        login ({ commit }, params) {
            commit('login', params)
        },
        logout ({ commit }) {
            commit('logout')
        },
    }
}