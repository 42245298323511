<template>
    <div class="pc-wrap">
        <div class="main">
            <div class="big-title">方糖CLUB</div>
        </div>
        <Sidebar />
        <Footer />
    </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar'
import Footer from '@/components/Footer'

export default {
    name: 'Home',
    components: {
        Sidebar,
        Footer
    }
}
</script>

<style lang="scss" scoped>

.main{
    width: 100%;
    height: 100vh;
    background: url('../assets/bg.svg') no-repeat center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    .big-title{
        font-size: 70px;
        font-weight: 700;
    }
}
</style>
