import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'

import { ask } from './utils/request'
import animateRouter from './mixins/animateRouter'
import innerUtils from './mixins/innerUtils'

Vue.mixin(animateRouter)
Vue.mixin(innerUtils)

Vue.prototype.$ask = ask
Vue.prototype.$moment = moment;

import { Button } from 'vant'
import { Field } from 'vant'
import { Search } from 'vant'
import { Card } from 'vant'
import { Tag } from 'vant'
import { Toast } from 'vant'
import { Form } from 'vant'
import { Loading } from 'vant'
import { Overlay } from 'vant'
import { List } from 'vant'
import { Dialog } from 'vant'
import { DropdownMenu, DropdownItem } from 'vant'
import { Cell, CellGroup } from 'vant';

Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Button)
Vue.use(Field)
Vue.use(Search)
Vue.use(Tag)
Vue.use(Card)
Vue.use(Toast)
Vue.use(Form)
Vue.use(Loading)
Vue.use(Overlay)
Vue.use(List)
Vue.use(Dialog)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
import { Tab, Tabs } from 'vant'

Vue.use(Tab)
Vue.use(Tabs)

import { NavBar } from 'vant';

Vue.use(NavBar);

import { Picker } from 'vant';

Vue.use(Picker);

import { Popup } from 'vant';

Vue.use(Popup);
import { DatetimePicker } from 'vant';

Vue.use(DatetimePicker);

import { RadioGroup, Radio } from 'vant';

Vue.use(Radio);
Vue.use(RadioGroup);

Vue.config.productionTip = false

function updateUser(){
    ask.post('/autoLogin')
        .then(res => {
            store.dispatch('login',res.data)
            return Promise.resolve()
        })
		.catch(err => {
			store.dispatch('logout')
            return Promise.resolve()
		})
}

const prePromise = [ updateUser() ]

Promise.all(prePromise)
	.then(() => {
		new Vue({
			router,
			store,
			render: h => h(App)
		}).$mount('#app')
	})

router.beforeEach((to, from, next) => {
	if (to.meta.title) {
		document.title = to.meta.title
	}
	const type = to.meta.type
	if (type === 'login') {
		if (store.state.user.hasLogin) {
			next()
		} else {
			next('/login')
		}
	} else {
		next()
	}
})
