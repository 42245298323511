export default {
    methods: {
        // 处理code不为0的情况 0成功 1失败 2token失效 406参数错误
        // actionType 0控制台输出错误信息 1弹提示含跳登录 2弹提示不跳转 3弹code!=2的提示
        $toastError (err,type) {
            let actionType = type==undefined?1:type;
            if(actionType==0){
                console.log(err)
            }
            else if(actionType==1){
                if(err.code==401){
                    this.$toast(err.msg)
                    this.$store.dispatch('logout').then(()=>{
                        this.$routerPush('/login')
                    })
                }
                else if(err.msg){
                    this.$toast(err.msg)
                }
                else if(err.status){
                    this.$toast(err.status)
                }
            }
            // 全部提示
            else if(actionType==2){
                if(err.msg){
                    this.$toast(err.msg)
                }
                else if(err.status){
                    this.$toast(err.status)
                }
            }
            // 不提示未登录
            else if(actionType==3){
                if(err.code ==2){
                    console.log(err)
                }
                else if(err.msg){
                    this.$toast(err.msg)
                }
                else if(err.status){
                    this.$toast(err.status)
                }
            }
        },
        $locationQuery2Json() {
            let url = location.href; // 获取当前浏览器的URL
            let param = {}; // 存储最终JSON结果对象
            url.replace(/([^?&]+)=([^?&]+)/g, function (s, v, k) {
                param[v] = decodeURIComponent(k); //解析字符为中文
                return k + '=' + v;
            });
            return param;
        },
        $unBuild(){
            this.$toast('功能开发中')
        }
    }
}