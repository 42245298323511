<template>
    <div class="app-view">
        <div class="form-wrap" v-if="type==1">
            <van-field v-model="username" name="账号" label="账号" placeholder="账号" :rules="[{ required: true, message: '请填写账号' }]" />
            <van-field v-model="password" type="password" name="密码" label="密码" placeholder="密码" :rules="[{ required: true, message: '请填写密码' }]" />

        </div>
        <div class="form-wrap" v-else>
            <van-field v-model="username" name="账号" label="账号" placeholder="输入账号(只能为数字和字母)" :rules="[{ required: true, message: '请填写用户名' }]" />
            <van-field v-model="password" type="password" name="密码" label="密码" placeholder="密码，大于6位" :rules="[{ required: true, message: '请填写密码' }]" />
            <van-field v-model="password2" type="password" name="确认密码" label="确认密码" placeholder="确认密码" :rules="[{ required: true, message: '请确认密码' }]" />
        </div>
        <div style="margin: 16px;">
            <van-button round block type="info" @click="submit">
                {{ type==1?'登录':'注册'}}
            </van-button>
            <div class="tips" @click="()=>{type=3-type}">{{ type==1?'没有账号，马上注册':'去登录' }}</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            username: '',
            password: '',
            password2: '',
            type: 1, // 1 登录 2 注册
        }
    },
    methods: {
        submit() {
            if (this.type == 1) {
                if (!this.username) {
                    this.$toast("账号不能为空");
                    return false;
                }
                if (!this.password) {
                    this.$toast("密码不能为空");
                    return false;
                }
                this.login()
            }
            else {
                if (!this.username || !this.password || !this.password2) {
                    this.$toast("请将注册信息填写完整");
                    return false;
                }
                else if (this.password.length < 6) {
                    this.$toast("密码不能小于6位");
                    return false;
                }
                else if (this.password != this.password2) {
                    this.$toast("两次密码不一致");
                    return false;
                }
                this.regist()
            }
        },
        login() {
            this.$ask.post('/login', { username: this.username, password: this.password})
                .then(res => {
                    this.$toast("登录成功");
                    this.$store.dispatch('login',res.data)
                        .then(this.$routerClearStack().then(() => this.$routerReplace('/index')))
                })
                .catch(this.$toastError)
        },
        regist() {
            this.$ask.post('/register', { username: this.username, password: this.password })
                .then(res => {
                    this.$toast('注册成功')
                    this.$store.dispatch('login',res.data)
                        .then(this.$routerClearStack().then(() => this.$routerReplace('/index')))
                })
                .catch(this.$toastError)
        }
    }
}
</script>

<style lang="scss" scoped>
.app-view {
    background-color: #fff;
}
.form-wrap {
    padding-top: 2vh;
}
.tips {
    margin: 20px auto;
    font-size: 14px;
    text-align: center;
    text-decoration: underline;
    line-height: 28px;
    color: rgb(95, 159, 255);
}
</style>