<template>
    <router-view v-if="hasPassed" />
    <div style="padding: 50px;" v-else>
        这里什么都没有哦😯
        <van-button style="margin-top:50px;" type="default" block @click="$routerPush('/')">返回首页</van-button>
    </div>
</template>

<script>
export default {
    data(){
        return {
            hasPassed: false
        }
    },
    created(){
        this.hasPassed = this.$store.state.user.hasLogin
    }
}
</script>
